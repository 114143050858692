<template>
  <v-stepper-header>
    <template v-for="n in steps">
      <v-stepper-step
        :key="`${n}-step`"
        :complete="currentStep > n"
        :step="n"
        editable
      >
        {{ stepperTitles[n - 1].title }}
      </v-stepper-step>

      <v-btn
        v-if="n !== 1"
        id="stepper-icon-remove"
        :key="`remove-step-${n}`"
        :title="$t('tender.removeStep')"
        icon
        color="error"
        @click.prevent="removeStep(n)"
      >
        <v-icon>
          {{ icons.mdiCloseCircle }}
        </v-icon>
      </v-btn>

      <v-btn
        id="stepper-icon-edit"
        :key="`edit-step-${n}`"
        :title="$t('tender.editField')"
        icon
        color="primary"
        @click.prevent="editStep(n - 1)"
      >
        <v-icon>
          {{ icons.mdiPencilCircle }}
        </v-icon>
      </v-btn>

      <v-divider
        v-if="n !== steps"
        :key="n"
      ></v-divider>
    </template>
    <div
      class="hidden-xs-only d-flex justify-end"
      :class="steps === 1 ? 'flex-grow-1' : ''"
    >
      <v-btn
        icon
        outlined
        color="primary"
        class="my-4 ml-4 mr-10"
        @click.prevent="addStepperItem"
      >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
      </v-btn>
    </div>
  </v-stepper-header>
</template>
<script>
export default {
  name: 'TenderStepperHeader',
  props: {
    currentStep: {},
    icons: {},
    stepperTitles: {},
    steps: {},
    addStepperItem: {},
    removeStep: {},
  },
}
</script>
