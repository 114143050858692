var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-actions"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-item rounded-0 rounded-lg",attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.$router.go(-1)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiArrowLeft)+" ")])],1)]}}])},[_c('span',[_vm._v("Return")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-item rounded-0 rounded-lg",attrs:{"icon":"","color":"white"},nativeOn:{"click":function($event){return _vm.$emit('admin-tender-preview')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilePresentationBox)+" ")])],1)]}}])},[_c('span',[_vm._v("Preview tender")])]),_c('div',{staticClass:"additional-options-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdditionalOptions),expression:"showAdditionalOptions"}],staticClass:"additional-options-container"},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"plain":"","small":"","text":""},on:{"click":function($event){return _vm.$emit('add-container-to-parent')}}},[_vm._v(" "+_vm._s(_vm.$t('tender.actionMenu.addContainer'))+" ")])],1),_c('v-list-item',[_c('v-btn',{attrs:{"plain":"","small":"","text":""}},[_vm._v(" "+_vm._s(_vm.$t('tender.actionMenu.duplicateTender'))+" ")])],1),_c('v-list-item',[_c('v-btn',{attrs:{"plain":"","small":"","text":""}},[_vm._v(" "+_vm._s(_vm.$t('tender.actionMenu.deleteTender'))+" ")])],1)],1)],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-item rounded-0 rounded-lg",class:_vm.showAdditionalOptions ? 'pressed': 'primary',attrs:{"icon":"","color":"white"},on:{"click":_vm.showAdditionalOptionsMenu}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsHorizontal)+" ")])],1)]}}])},[_c('span',[_vm._v("Additional options")])])],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-item rounded-0 rounded-lg",attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('save-tender')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheck)+" ")])],1)]}}])},[_c('span',[_vm._v("Save changes")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }