export default [
  {
    key: 'subtitle',
    name: 'Subtitle',
  },
  {
    key: 'description',
    name: 'Description',
  },
  {
    key: 'text',
    name: 'Short text',
  },
  {
    key: 'textarea',
    name: 'Long text',
  },
  {
    key: 'number',
    name: 'Number',
  },
  {
    key: 'radio',
    name: 'Radio button',
  },
  {
    key: 'file',
    name: 'File upload',
  },
  {
    key: 'checkbox',
    name: 'Checkbox',
  },
  {
    key: 'date',
    name: 'Date',
  },
]
