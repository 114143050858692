<template>
  <v-dialog
    v-model="inputTypeDialog"
    max-width="800px"
  >
    <v-card>
      <v-card-title>
        {{ $t('tender.inputFieldType') }}
      </v-card-title>
      <v-row class="no-gutters pa-5">
        <v-col
          lg="6"
          md="6"
          cols="12"
          class="pa-2"
        >
          <v-text-field
            v-model="inputFieldLabel"
            dense
            outlined
            :label="$t('tender.fieldLabel')"
          ></v-text-field>
        </v-col>
        <v-col
          lg="6"
          md="6"
          cols="12"
          class="pa-2"
        >
          <v-select
            v-model="inputFieldOption"
            dense
            outlined
            :label="$t('tender.chooseInputType')"
            :items="fieldOptions"
            :item-text="item => $t(item.name)"
            item-value="key"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
        >
          <v-checkbox
            v-model="isRequested"
            :label="$t('tender.dataRequested')"
          >
          </v-checkbox>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="closeFieldsDialog"
        >
          {{ $t('general.cancel') }}
        </v-btn>
        <v-btn
          color="success"
          @click="addFieldConfirm"
        >
          {{ $t('general.ok') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import fieldOptions from '@/views/admin/Tender/fieldOptions'

export default {
  name: 'AddRowDialog',
  props: {
    closeFieldsDialog: null,
    inputTypeDialog: null,
  },
  data() {
    const inputFieldOption = null
    const inputFieldLabel = null
    const isRequested = false

    return {
      inputFieldOption,
      inputFieldLabel,
      isRequested,
      fieldOptions,
    }
  },
  methods: {
    addFieldConfirm() {
      this.$emit('input-field-type-selected', {
        inputFieldOption: this.inputFieldOption,
        inputFieldLabel: this.inputFieldLabel,
        isRequested: this.isRequested,
      })
      this.inputFieldOption = null
      this.inputFieldLabel = null
      this.isRequested = false
    },
  },
}
</script>
