<template>
  <div>
    <v-dialog
      v-model="showAddressDialogMutable"
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          {{ $t('tender.addressNotice') }}
        </v-card-title>

        <v-row
          v-if="showErrorMessage"
          class="no-gutters px-5"
        >
          <v-alert
            class="w-full"
            type="error"
            v-text="$t('tender.addressDataNotSelected')"
          >
          </v-alert>
        </v-row>

        <v-row class="no-gutters px-5">
          <v-col
            cols="10"
            class="px-2"
          >
            <v-select
              v-model="addressType"
              dense
              outlined
              :label="$t('tender.chooseAddressType')"
              :items="$store.state.addressTypeOptions"
              :item-text="item => $t(item.name)"
              item-value="key"
              class="pb-0"
              hide-details
            ></v-select>
          </v-col>
          <v-col
            align="right"
            cols="2"
          >
            <v-btn
              icon
              dark
              outlined
              color="primary"
              class="mx-2"
              @click.prevent="showAddressModal = true"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="no-gutters pa-5">
          <v-data-table
            v-model="contactSelectedRows"
            class="w-full"
            :headers="contactTableColumns"
            :items="contactListTable"
            :items-per-page="10"
            :options.sync="optionContacts"
            :server-items-length="totalContacts"
            :loading="loadingContacts"
            disable-sort
            show-select
            single-select
            selectable-key="id"
          >
            <template #[`item.name`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column ms-3">
                  {{ item.name }}
                </div>
              </div>
            </template>

            <template #[`item.address`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column ms-3">
                  {{ item.address }}
                </div>
              </div>
            </template>

            <template #[`item.zip_code`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column ms-3">
                  {{ item.zip_code }}
                </div>
              </div>
            </template>

            <template #[`item.city`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column ms-3">
                  {{ item.city }}
                </div>
              </div>
            </template>

            <template #[`item.phone`]="{item}">
              <div class="d-flex align-center">
                <span class="text-capitalize">{{ item.phone }}</span>
              </div>
            </template>

            <template #[`item.address_type`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column ms-3">
                  {{ $t($store.getters.formatKeyForTranslation(item.address_type).name) }}
                </div>
              </div>
            </template>
          </v-data-table>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeAddressesDialog"
          >
            {{ $t('general.cancel') }}
          </v-btn>
          <v-btn
            color="success"
            @click="addAddressConfirm"
          >
            {{ $t('general.ok') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AddAddressModal
      :company-id="company"
      :contact="{}"
      :show-address-modal="showAddressModal"
      @new-address-added="newAddressAdded"
      @close-address-modal="showAddressModal = false"
    />
  </div>
</template>
<script>
import axiosIns from '@axios'
import { mdiPlus } from '@mdi/js'
import AddAddressModal from '@/views/shared/components/AddAddressModal.vue'

export default {
  name: 'AddAddressDialog',
  components: { AddAddressModal },
  props: {
    closeAddressesDialog: {},
    showAddressDialog: {},
    company: {},
  },
  data() {
    const showAddressModal = false
    const contactSelectedRows = []
    const optionContacts = null
    const loadingContacts = true
    const totalContacts = 0
    const contactListTable = []
    const addressType = null
    const showErrorMessage = false
    const showAddressDialogMutable = this.showAddressDialog

    const contactTableColumns = [
      { text: 'NAME', value: 'name' },
      { text: 'ADDRESS', value: 'address' },
      { text: 'ZIP CODE', value: 'zip_code' },
      { text: 'CITY', value: 'city' },
      { text: 'PHONE', value: 'phone' },
      { text: 'TYPE', value: 'address_type' },
    ]

    return {
      showAddressModal,
      showAddressDialogMutable,
      contactSelectedRows,
      contactTableColumns,
      optionContacts,
      loadingContacts,
      totalContacts,
      contactListTable,
      addressType,
      showErrorMessage,
      icons: {
        mdiPlus,
      },
    }
  },
  watch: {
    showAddressDialogMutable() {
      if (this.showAddressDialogMutable === false) {
        this.closeAddressesDialog()
      }
    },
    optionContacts: {
      handler() {
        this.getCompanyContacts()
      },
      deep: true,
    },
  },
  methods: {
    newAddressAdded() {
      this.getCompanyContacts()
    },

    addAddressConfirm() {
      if (this.addressType && this.contactSelectedRows.length !== 0) {
        this.$emit('added-additional-address', {
          type: this.addressType,
          contact: this.contactSelectedRows[0],
        })
      } else {
        this.showErrorMessage = true
      }
    },

    getCompanyContacts() {
      if (this.company) {
        this.loadingContacts = true
        axiosIns.get(`/admin/companies/${this.company}/contacts?page=${this.optionContacts.page}&perPage=
      ${this.optionContacts.itemsPerPage}`)
          .then(res => {
            this.totalContacts = res.data.contacts.total
            this.contactListTable = res.data.contacts.data
          })
          .catch()
      }
      this.loadingContacts = false
    },

  },
}
</script>
