<template>
  <v-form class="w-full">
    <slot></slot>
  </v-form>
</template>

<script>
export default {
  name: 'Form',
}
</script>

<style scoped>

</style>
