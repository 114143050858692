var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.showAddressDialogMutable),callback:function ($$v) {_vm.showAddressDialogMutable=$$v},expression:"showAddressDialogMutable"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('tender.addressNotice'))+" ")]),(_vm.showErrorMessage)?_c('v-row',{staticClass:"no-gutters px-5"},[_c('v-alert',{staticClass:"w-full",attrs:{"type":"error"},domProps:{"textContent":_vm._s(_vm.$t('tender.addressDataNotSelected'))}})],1):_vm._e(),_c('v-row',{staticClass:"no-gutters px-5"},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"10"}},[_c('v-select',{staticClass:"pb-0",attrs:{"dense":"","outlined":"","label":_vm.$t('tender.chooseAddressType'),"items":_vm.$store.state.addressTypeOptions,"item-text":function (item) { return _vm.$t(item.name); },"item-value":"key","hide-details":""},model:{value:(_vm.addressType),callback:function ($$v) {_vm.addressType=$$v},expression:"addressType"}})],1),_c('v-col',{attrs:{"align":"right","cols":"2"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","dark":"","outlined":"","color":"primary"},on:{"click":function($event){$event.preventDefault();_vm.showAddressModal = true}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))])],1)],1)],1),_c('v-row',{staticClass:"no-gutters pa-5"},[_c('v-data-table',{staticClass:"w-full",attrs:{"headers":_vm.contactTableColumns,"items":_vm.contactListTable,"items-per-page":10,"options":_vm.optionContacts,"server-items-length":_vm.totalContacts,"loading":_vm.loadingContacts,"disable-sort":"","show-select":"","single-select":"","selectable-key":"id"},on:{"update:options":function($event){_vm.optionContacts=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_vm._v(" "+_vm._s(item.address)+" ")])])]}},{key:"item.zip_code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_vm._v(" "+_vm._s(item.zip_code)+" ")])])]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_vm._v(" "+_vm._s(item.city)+" ")])])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.phone))])])]}},{key:"item.address_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$store.getters.formatKeyForTranslation(item.address_type).name))+" ")])])]}}],null,true),model:{value:(_vm.contactSelectedRows),callback:function ($$v) {_vm.contactSelectedRows=$$v},expression:"contactSelectedRows"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":_vm.closeAddressesDialog}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.addAddressConfirm}},[_vm._v(" "+_vm._s(_vm.$t('general.ok'))+" ")]),_c('v-spacer')],1)],1)],1),_c('AddAddressModal',{attrs:{"company-id":_vm.company,"contact":{},"show-address-modal":_vm.showAddressModal},on:{"new-address-added":_vm.newAddressAdded,"close-address-modal":function($event){_vm.showAddressModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }