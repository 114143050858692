<template>
  <div class="app-actions">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          class="action-item rounded-0 rounded-lg"
          color="white"
          v-bind="attrs"
          v-on="on"
          @click="$router.go(-1)"
        >
          <v-icon size="25">
            {{ icons.mdiArrowLeft }}
          </v-icon>
        </v-btn>
      </template>
      <span>Return</span>
    </v-tooltip>

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          class="action-item rounded-0 rounded-lg"
          color="white"
          v-bind="attrs"
          v-on="on"
          @click.native="$emit('admin-tender-preview')"
        >
          <v-icon size="25">
            {{ icons.mdiFilePresentationBox }}
          </v-icon>
        </v-btn>
      </template>
      <span>Preview tender</span>
    </v-tooltip>

    <div class="additional-options-wrapper">
      <div
        v-show="showAdditionalOptions"
        class="additional-options-container"
      >
        <v-list>
          <v-list-item>
            <v-btn
              plain
              small
              text
              @click="$emit('add-container-to-parent')"
            >
              {{ $t('tender.actionMenu.addContainer') }}
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              plain
              small
              text
            >
              {{ $t('tender.actionMenu.duplicateTender') }}
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              plain
              small
              text
            >
              {{ $t('tender.actionMenu.deleteTender') }}
            </v-btn>
          </v-list-item>
        </v-list>
      </div>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            class="action-item rounded-0 rounded-lg"
            :class="showAdditionalOptions ? 'pressed': 'primary'"
            color="white"
            v-bind="attrs"
            v-on="on"
            @click="showAdditionalOptionsMenu"
          >
            <v-icon size="25">
              {{ icons.mdiDotsHorizontal }}
            </v-icon>
          </v-btn>
        </template>
        <span>Additional options</span>
      </v-tooltip>
    </div>

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          class="action-item rounded-0 rounded-lg"
          color="white"
          v-bind="attrs"
          v-on="on"
          @click="$emit('save-tender')"
        >
          <v-icon size="25">
            {{ icons.mdiCheck }}
          </v-icon>
        </v-btn>
      </template>
      <span>Save changes</span>
    </v-tooltip>
  </div>
</template>

<script>
import {
  mdiArrowLeft,
  mdiCheck,
  mdiClose,
  mdiDotsHorizontal,
  mdiFilePresentationBox,
} from '@mdi/js'

export default {
  components: {},
  props: { tenderId: Number },
  setup() {
    const showAdditionalOptions = false
    const containerBaseModel = {
      subtitle: '',
      description: '',
      rows: [
        [{
          value: '',
          type: 'text',
          label: 'Text',
        }],
      ],
    }

    return {
      containerBaseModel,
      showAdditionalOptions,
      icons: {
        mdiArrowLeft,
        mdiCheck,
        mdiClose,
        mdiDotsHorizontal,
        mdiFilePresentationBox,
      },
    }
  },
  methods: {
    showAdditionalOptionsMenu() {
      this.showAdditionalOptions = !this.showAdditionalOptions
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-actions {
  position: fixed !important;
  top: 42% !important;
  right: -22px;
  z-index: 7;
  padding: .3em .75em .3em 1.75em;
  background-color: #fff;
  box-shadow: -3px 1px 5px rgba(0, 0, 0, 0.1);
}

.action-item {
  transform: translateX(-50%);
  background: var(--v-primary-base);
  display: block;
  margin: 3px -4px 3px 0;
}

.additional-options-wrapper {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
}

.additional-options-container {
  position: absolute;
  width: 14em;
  right: 3.90em;
  top: 0;
  box-shadow: -3px 1px 5px rgba(0, 0, 0, 0.1);
}

.additional-options-container .v-btn__content {
  opacity: 1 !important;
}

.pressed {
  background: #94cfeb;
}

</style>
