<template>
  <v-dialog
    v-model="removeContainerDialog"
    max-width="800px"
  >
    <v-card>
      <v-card-title
        class="text-center"
        align="center"
      >
        {{ $t('tender.removeContainerNotice') }}
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="closeRemoveContainerDialog"
        >
          {{ $t('general.cancel') }}
        </v-btn>
        <v-btn
          color="success"
          @click="removeContainerConfirm"
        >
          {{ $t('general.ok') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'RemoveContainerDialog',
  props: {
    closeRemoveContainerDialog: null,
    removeContainerConfirm: null,
    removeContainerDialog: null,
  },
}
</script>
