import axiosIns from '@axios'

function generateRandomNumber() {
  return parseInt(Math.random() * (999999 - 10000000) + 10000000, 10)
}

function formatDate(index, row, col) {
  const [year, month, day] = this.formItems[index].rows[row][col].value.split('-')
  this.formItems[index].rows[row][col].value = `${month}/${day}/${year}`
}

function clearCompany() {
  this.company = {}
  this.selectedCompany = null
}

function filterCompanies(filter) {
  if (filter && filter.length >= 2) {
    axiosIns.get(`/admin/companies?filter=${filter}`).then(res => {
      this.companies = res.data.companies.data
    }).catch(err => { console.log(err) })
  }
}

function moveContainerDown(index) {
  const currentItem = this.formItems[index]
  if (index < this.formItems.length - 1 && currentItem.stepperId === this.formItems[index + 1].stepperId) {
    this.formItems[index] = this.formItems[index + 1]
    this.formItems[index + 1] = currentItem
  }

  /* reactivate from */
  this.formItems.push()
}

function moveContainerUp(index) {
  const currentItem = this.formItems[index]
  if (index > 0 && currentItem.stepperId === this.formItems[index - 1].stepperId) {
    this.formItems[index] = this.formItems[index - 1]
    this.formItems[index - 1] = currentItem
  }

  /* reactivate from */
  this.formItems.push()
}

function addContainer(stepperId = this.currentStep) {
  this.formItems.push({
    stepperId,
    containerId: generateRandomNumber(),
    subtitle: '',
    description: '',
    rows: [
      [],
    ],
  })
}

function editFieldConfirm() {
  this.formItems[this.currentContainerIndex].rows[this.currentRowIndex][this.currentColIndex].type = this.inputFieldOption
  this.formItems[this.currentContainerIndex].rows[this.currentRowIndex][this.currentColIndex].label = this.inputFieldLabel
  this.formItems[this.currentContainerIndex].rows[this.currentRowIndex][this.currentColIndex].isRequested = this.isRequested
  this.inputFieldOption = null
  this.inputFieldLabel = null
  this.currentContainerIndex = null
  this.currentRowIndex = null
  this.currentColIndex = null
  this.editFieldDialog = false
}

function editField(index, row, col, type, label, isRequested) {
  this.currentContainerIndex = index
  this.currentRowIndex = row
  this.currentColIndex = col
  this.inputFieldOption = type
  this.inputFieldLabel = label
  this.isRequested = isRequested
  this.editFieldDialog = true
}

function closeEditDialog() {
  this.inputFieldOption = null
  this.inputFieldLabel = null
  this.isRequested = false
  this.currentContainerIndex = null
  this.currentRowIndex = null
  this.currentColIndex = null
  this.editFieldDialog = false
}

function addFieldConfirm(value) {
  const { inputFieldOption, inputFieldLabel, isRequested } = value
  switch (inputFieldOption) {
    case 'description':
      this.formItems[this.currentContainerIndex].description = ''
      break
    case 'subtitle':
      this.formItems[this.currentContainerIndex].subtitle = ''
      break
    default:
      // eslint-disable-next-line no-unused-expressions
      this.currentRowIndex === null ? this.formItems[this.currentContainerIndex].rows.push([{
        value: '',
        type: inputFieldOption,
        label: inputFieldLabel,
        isRequested,
      }]) : this.formItems[this.currentContainerIndex].rows[this.currentRowIndex].push({
        value: '',
        type: inputFieldOption,
        label: inputFieldLabel,
        isRequested,
      })
  }
  this.inputTypeDialog = false
  this.inputFieldOption = null
  this.inputFieldLabel = null
  this.isRequested = false
  this.currentContainerIndex = null
  this.currentRowIndex = null
}

// dynamic stepper function
function addStepperItem() {
  this.showStepperModal = true
}

function addStepperItemConfirm() {
  this.steps += 1
  this.stepperTitles.push({ title: this.stepperTitle })
  this.stepperTitle = null
  this.showStepperModal = false
}

function removeStep(n) {
  this.steps -= 1
  this.formItems = this.formItems.filter(item => (item.stepperId !== n))
  this.formItems = this.formItems.map(item => {
    if (item.stepperId > n) {
      // eslint-disable-next-line no-param-reassign
      item.stepperId -= 1
    }

    return item
  })
  this.stepperTitles.splice(n - 1, 1)
}

function editStep(n) {
  this.showEditStepperModal = true
  this.selectedStepperItem = n
  this.stepperTitle = this.stepperTitles[n].title
}

function editStepperItemConfirm() {
  this.stepperTitles[this.selectedStepperItem].title = this.stepperTitle
  this.selectedStepperItem = null
  this.stepperTitle = null
  this.showEditStepperModal = false
}

function closeEditStepperItemModal() {
  this.selectedStepperItem = null
  this.stepperTitle = null
  this.showEditStepperModal = false
}

function addAddressConfirm(value) {
  this.addresses.push(value)
  this.showAddressDialog = false
}

function addAddressDialog() {
  if (this.selectedCompany) {
    this.showAddressDialog = true
  }
}

function closeAddressesDialog() {
  this.showAddressDialog = false
}

function removeAddress(id) {
  this.addresses = this.addresses.filter(item => item.id !== id)
  this.deletedAddresses.push(id)
}

function closeRemoveContainerDialog() {
  this.removeContainerDialog = false
}

function removeContainer() {
  this.removeContainerDialog = true
}

function removeContainerConfirm(index) {
  this.formItems.splice(index, 1)
  this.removeContainerDialog = false
}

function addFieldsDialog(index, row = null) {
  this.inputTypeDialog = true
  this.currentContainerIndex = index
  this.currentRowIndex = row
}

function closeFieldsDialog() {
  this.inputTypeDialog = false
  this.currentContainerIndex = null
}

function removeRow(index, row) {
  this.formItems[index].rows.splice(row, 1)
}

function addField(index, row) {
  this.inputTypeDialog = true
  this.currentContainerIndex = index
  this.currentRowIndex = row
}

function removeField(index, row, col) {
  this.formItems[index].rows[row].splice(col, 1)
}

function handleFilesUpload(event, index, row, col) {
  const fileNumber = generateRandomNumber()
  this.formItems[index].rows[row][col].value = fileNumber
  this.formItems[index].rows[row][col].originalName = event.name
  this.files.push({ id: fileNumber, file: event })
}

export default {
  generateRandomNumber,
  formatDate,
  clearCompany,
  filterCompanies,
  moveContainerDown,
  moveContainerUp,
  addContainer,
  editFieldConfirm,
  editField,
  closeEditDialog,
  addFieldConfirm,
  addStepperItem,
  addStepperItemConfirm,
  removeStep,
  editStep,
  editStepperItemConfirm,
  closeEditStepperItemModal,
  addAddressConfirm,
  addAddressDialog,
  closeAddressesDialog,
  removeAddress,
  closeRemoveContainerDialog,
  removeContainer,
  removeContainerConfirm,
  addFieldsDialog,
  closeFieldsDialog,
  removeRow,
  addField,
  removeField,
  handleFilesUpload,
}
